import { Flex } from '@storyofams/react-ui';
import { NextSeo } from 'next-seo';
import { Button, Container, Heading, Text } from '~components';

const getError = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const getContent = ({ statusCode }) => {
  switch (statusCode) {
    case 401:
      return "It looks like you're not supposed to be here 👀";
    case 404:
      return 'We could not find the page you were looking for 🛰';
    case 500:
      return 'Our server had some trouble processing that request 🔥';
    default:
      return "Even we don't know what happened 🤯";
  }
};

const Error = ({ statusCode }) => {
  const content = getContent({ statusCode });

  return (
    <>
      <NextSeo title={statusCode} description={content} />
      <Flex
        height={[
          'calc(100vh - 52px)',
          'calc(100vh - 52px)',
          'calc(100vh - 80px)',
        ]}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Container alignItems="center" space={0}>
          <Heading variant="h1" mb={2}>
            {statusCode}
          </Heading>

          <Text mb={5} textAlign="center" justifyContent="center">
            {content}
          </Text>

          <Button variant="primary" to="/">
            Take me home
          </Button>
        </Container>
      </Flex>
    </>
  );
};

Error.getInitialProps = async ({ res, err }) => {
  return {
    props: {},
    ...getError({ res, err }),
  };
};

export default Error;
